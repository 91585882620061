import axios from 'axios';
import { setCustomFieldsForApiResult } from 'helpers/setCustomFields';

export default async function setCloneItemDetails({
  type,
  targetId,
  setFormData
}) {
  return new Promise((resolve, reject) => {
    const availableApiEndPoints = {
      PR: '/purchase/purchase-request/',
      PO: '/purchase/purchase-order/',
      PI: '/purchase/purchase-invoice/',
      PD: '/purchase/purchase-delivery/'
    };

    if (!availableApiEndPoints?.[type])
      return reject({
        message: 'Invalid type'
      });

    if (typeof targetId !== 'string')
      return reject({
        message: 'Invalid target id'
      });

    if (typeof setFormData !== 'function')
      return reject({
        message: 'Invalid setFormData, this must be a function'
      });

    const apiEndPoint = availableApiEndPoints[type];
    axios
      .get(apiEndPoint + targetId)
      .then(repsponse => {
        const targetDetails = repsponse?.data?.data;
        if (repsponse?.status === 200 && targetDetails?.id) {
          let result = generateTypeRelatedResult({
            type: type,
            targetDetails: targetDetails
          });
          setFormData(result);
          resolve({
            data: result
          });
        }
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
}

function generateTypeRelatedResult({ type, targetDetails }) {
  //   common data
  const tax_included = targetDetails?.tax_included ?? 0;
  let result = {
    trans_date: targetDetails?.trans_date ?? '',
    cost_center_id: targetDetails?.cost_center_id ?? null,
    cost_center_id_ref: targetDetails?.cost_center_id
      ? {
          value: targetDetails.cost_center_id,
          label: targetDetails?.cost_center_name
        }
      : null,
    warehouse_id: targetDetails?.warehouse_id ?? null,
    warehouse_id_ref: targetDetails?.warehouse_id
      ? {
          value: targetDetails?.warehouse_id,
          label: targetDetails?.warehouse_name
        }
      : null,
    tax_included: tax_included,
    details: Array.isArray(targetDetails?.details)
      ? targetDetails.details.reduce((newResult, item) => {
          let newData = {
            id_ref: newResult?.length + 1,
            stock_id: item?.stock_id ?? '-',
            item_display_name: item?.item_display_name ?? '',
            item_display_name_ref: item?.item_display_name
              ? {
                  item: item?.item_basic_details ?? item?.item,
                  label: item?.item_display_name,
                  value: item?.id
                }
              : null,
            item_description: item?.item_description ?? '',
            quantity: item?.quantity ?? '',
            unit_price: item?.unit_price ?? '',
            unit_price_formatted: item?.unit_price_formatted ?? '',
            discount_amount: item?.discount_amount ?? 0,
            discount_amount_formatted: item?.discount_amount_formatted ?? '',
            unit_tax: item?.unit_tax ?? 0,
            unit_tax_formatted: item?.unit_tax_formatted ?? '',
            discount_percent: item?.discount_percent ?? 0,
            discount_unit_ref: '$',
            unit_ref: item?.unit_name ?? '',
            is_kit: item?.is_kit ?? '',
            trans_date: targetDetails?.trans_date ?? '',
            line_total_formatted: item?.line_total_formatted,
            tax_included: tax_included,
            add_to_cart_ref: true,
            add_to_draft_ref: false,
            edit: true
          };

          if ([true, 'true', '1', 1].includes(item?.is_kit)) {
            newData.is_kit = item?.is_kit;
            newData.kit_id = item?.kit_id;
            newData.kit_items = item?.kit_items;
          }

          if (item?.tax_group_id) {
            newData.tax_group_id = item.tax_group_id;
            newData.tax_group_id_ref = {
              label: item?.tax_group_name,
              value: item?.tax_group_id
            };
          }

          if (['PD', 'PI'].includes(type)) {
            newData.batch_number = item?.batch_number;
            newData.batch_number_ref = item?.batch_number
              ? {
                  label: item?.batch_number_display,
                  value: item.batch_number
                }
              : null;
          }

          newResult.push(newData);
          return newResult;
        }, [])
      : [],
    files: [],
    memo: targetDetails?.memo
  };

  if (['PO', 'PI', 'PD'].includes(type)) {
    result = {
      ...result,
      supplier_id: targetDetails?.supplier_id ?? null,
      supplier_id_ref: targetDetails?.supplier_id
        ? {
            value: targetDetails.supplier_id,
            label: `${targetDetails?.supplier_code} ${targetDetails?.supplier_name}`
          }
        : null,
      payment_term_id: targetDetails?.payment_term_id ?? null,
      payment_term_id_ref: targetDetails?.payment_term_id
        ? {
            value: targetDetails.payment_term_id,
            label: targetDetails?.payment_term
          }
        : null,
      phone: targetDetails?.phone ?? '',
      email: targetDetails?.email ?? ''
    };
  }

  if (['PO', 'PD', 'PI'].includes(type)) {
    result = {
      ...result,
      due_date: targetDetails?.due_date ?? ''
    };
  }

  if (type === 'PI') {
    result = {
      ...result,
      supplier_reference: targetDetails?.supplier_reference ?? '',
      fa_stock_id: targetDetails?.fa_stock_id,
      fa_stock_id_ref: targetDetails?.fa_stock_id
        ? {
            label: targetDetails?.fa_stock_name,
            value: targetDetails.fa_stock_id
          }
        : null,
      gl_items: Array.isArray(targetDetails?.gl_items)
        ? targetDetails.gl_items.reduce((newResult, item) => {
            let glData = {
              id_ref: newResult.length,
              amount: item?.amount,
              account_code: item?.account_code,
              account_code_option_ref: item?.account_code,
              account_code_ref: {
                code: item?.account_code,
                label: item?.account_code + ' - ' + item?.account_name,
                value: item?.account_code
              },
              cost_center_id: item?.cost_center ?? '',
              cost_center_id_ref: item?.cost_center
                ? {
                    label: item?.cost_center_name,
                    value: item?.cost_center
                  }
                : null,
              description: item?.description,
              tax_group_id: item?.applied_tax_group_id,
              tax_group_id_ref: {
                label: item?.applied_tax_group?.name,
                value: item?.applied_tax_group?.id,
                tax_details: item?.applied_tax_group
              }
            };
            newResult.push(glData);
            return newResult;
          }, [])
        : []
    };
  }

  if (type !== 'PR') {
    result.supplierDetails_ref = targetDetails?.supplier;
  }

  //set custom fields data
  if (targetDetails?.custom_field_values) {
    result = {
      ...result,
      ...setCustomFieldsForApiResult(targetDetails.custom_field_values)
    };
  }

  return result;
}
