import { React, useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';

const CollapseItems = ({ route, handleNavItemClick }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const stringifiedRoute = JSON.stringify(route || null);

  const handleClick = nav => {
    if (open) {
      setOpen(false);
    } else {
      if (nav.to) {
        navigate('/' + nav.to);
        setOpen(true);
      } else {
        setOpen(!open);
      }
    }
  };

  useEffect(() => {
    const parsedRoute = JSON.parse(stringifiedRoute);
    if (!parsedRoute) return;

    const formatSubName = subName => {
      if (subName && subName.includes('/')) {
        return subName.split('/').slice(0, 2).join('/');
      }
      return subName;
    };

    if (parsedRoute.validatingName) {
      setOpen(
        pathname.split('/')[1].toLocaleLowerCase() ===
          parsedRoute.validatingName.toLocaleLowerCase()
      );
    } else if (parsedRoute.subName1) {
      const path = pathname.split('/').slice(0, 3).join('/');
      const formattedSubNames = [
        parsedRoute.subName1,
        parsedRoute.subName2,
        parsedRoute.subName3,
        parsedRoute.subName4,
        parsedRoute.subName5,
        parsedRoute.subName6,
        parsedRoute.subName7,
        parsedRoute.subName8,
        parsedRoute.subName9,
        parsedRoute.subName10,
        parsedRoute.subName11,
        parsedRoute.subName12,
        parsedRoute.subName13,
        parsedRoute.subName14
      ].map(formatSubName);
      setOpen(
        [
          `/${parsedRoute.to}`,
          ...formattedSubNames.map(name => `/${name}`)
        ].includes(path)
      );
    } else {
      setOpen(
        pathname.split('/')[1].toLocaleLowerCase() ===
          parsedRoute.to.toLocaleLowerCase()
      );
    }
  }, [pathname, stringifiedRoute]);

  // onClick={e => {
  //         if (e.clientX >= 168 && e.clientX < 182) {
  //           setOpen(true);
  //         } else {
  //           handleClick(route);
  //         }
  //       }}

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => handleClick(route)}
        className={classNames(
          `dropdown-indicator cursor-pointer pb-0 pe-0 ${route?.tag ?? ''}`,
          {
            'text-500': !route.active
          }
        )}
        aria-expanded={open}
      >
        <NavbarVerticalMenuItem
          route={route}
          open={open}
          handleNavItemClick={handleNavItemClick}
        />
      </Nav.Link>
      <Collapse in={open}>
        <Nav
          className={
            route.children && open
              ? 'flex-column nav active-style-with-children'
              : 'flex-column nav'
          }
          as="ul"
        >
          <NavbarVerticalMenu
            routes={route.children}
            handleNavItemClick={handleNavItemClick}
          />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  handleNavItemClick: PropTypes.func,
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    to: PropTypes.any,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
    validatingName: PropTypes.string,
    subName1: PropTypes.string,
    subName2: PropTypes.string,
    subName3: PropTypes.string,
    subName4: PropTypes.string,
    subName5: PropTypes.string,
    subName6: PropTypes.string,
    subName7: PropTypes.string,
    subName8: PropTypes.string,
    subName9: PropTypes.string,
    subName10: PropTypes.string,
    subName11: PropTypes.string,
    subName12: PropTypes.string,
    subName13: PropTypes.string,
    subName14: PropTypes.string,
    tag: PropTypes.string
  })
};

const NavbarVerticalMenu = ({ routes }) => {
  const { pathname } = useLocation();
  const {
    config: { showBurgerMenu, isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries(queryParams);
  const { role_id, role_name } = allQueryParams;

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
    if (isNavbarVerticalCollapsed) {
      setConfig('isNavbarVerticalCollapsed', false);
    }
  };

  return (
    routes.length > 0 &&
    routes.map((route, index) => {
      if (!route.children) {
        //update route with query params for roles-permissions
        const currentRoute =
          route.to === 'system-admin/roles-permissions' && role_id && role_name
            ? `${route.to}?${createSearchParams({
                ...allQueryParams,
                role_id,
                role_name
              })}`
            : route.to;

        return (
          <div key={index} className="m-0">
            <Nav.Item
              as="li"
              key={route.name}
              onClick={handleNavItemClick}
              className="m-0"
            >
              <NavLink
                end={route.exact}
                to={currentRoute}
                state={{ open: route.to === '/authentication-modal' }}
                className={({ isActive }) => {
                  let className =
                    pathname == '/sales/customer/add' ||
                    pathname == '/purchase/supplier/add'
                      ? '/' + currentRoute == pathname
                        ? 'active active-style nav-link'
                        : 'nav-link'
                      : isActive
                      ? 'active active-style nav-link'
                      : 'nav-link';
                  return className + ` ${route?.tag ?? ''}`;
                }}
              >
                <NavbarVerticalMenuItem
                  route={route}
                  key={route.name}
                  handleNavItemClick={handleNavItemClick}
                />
              </NavLink>
            </Nav.Item>
          </div>
        );
      }
      return (
        <div key={index}>
          <CollapseItems
            route={route}
            key={route.name}
            handleNavItemClick={handleNavItemClick}
          />
        </div>
      );
    })
  );
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.any
};

export default NavbarVerticalMenu;
