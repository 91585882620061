import React from 'react';
import PropTypes from 'prop-types';
import { FaFile, FaFolder, FaFolderOpen } from 'react-icons/fa';
const TreeNode = ({ node, handleSelect }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);
  return (
    <div className="ms-3">
      <div className="mt-2 cursor-pointer d-flex align-items-center sub-category-tree-node">
        <div onClick={toggleExpand}>
          {node.subcategories?.length > 0 ? (
            isExpanded ? (
              <FaFolderOpen
                size={18}
                color="rgb(242 205 86)"
                className="me-3"
              />
            ) : (
              <FaFolder size={18} color="rgb(242 205 86)" className="me-3" />
            )
          ) : (
            <FaFile size={18} color="rgb(90 172 247 / 88%)" className="me-3 " />
          )}
        </div>
        <div
          className=" hover-card ps-2 pe-2"
          onClick={() => handleSelect(node)}
        >
          {' '}
          {node.name}
        </div>
      </div>
      {isExpanded &&
        node.subcategories?.map(child => (
          <TreeNode key={child.id} node={child} handleSelect={handleSelect} />
        ))}
    </div>
  );
};
TreeNode.propTypes = {
  node: PropTypes.any,
  handleSelect: PropTypes.func
};
export default TreeNode;
