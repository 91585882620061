const commonSortData = {
  trans_date: [
    {
      label: 'DATE - ASCENDING',
      value: 'trans_date,ASC'
    },
    {
      label: 'DATE - DESCENDING',
      value: 'trans_date,DESC'
    }
  ],
  amount: [
    {
      label: 'AMOUNT - ASCENDING',
      value: 'amount,ASC'
    },
    {
      label: 'AMOUNT - DESCENDING',
      value: 'amount,DESC'
    }
  ],
  sales_amount: [
    {
      label: 'SALES AMOUNT - ASCENDING',
      value: 'sales_amount,ASC'
    },
    {
      label: 'SALES AMOUNT - DESCENDING',
      value: 'sales_amount,DESC'
    }
  ],
  tax_amount: [
    {
      label: 'TAX AMOUNT - ASCENDING',
      value: 'tax_amount,ASC'
    },
    {
      label: 'TAX AMOUNT - DESCENDING',
      value: 'tax_amount,DESC'
    }
  ],
  total_invoice_amount: [
    {
      label: 'INVOICE AMOUNT - ASCENDING',
      value: 'total_invoice_amount,ASC'
    },
    {
      label: 'INVOICE AMOUNT - DESCENDING',
      value: 'total_invoice_amount,DESC'
    }
  ],
  total_tax: [
    {
      label: 'TAX AMOUNT - ASCENDING',
      value: 'total_tax,ASC'
    },
    {
      label: 'TAX AMOUNT - DESCENDING',
      value: 'total_tax,DESC'
    }
  ],
  invoice_count: [
    {
      label: 'INVOICE COUNT - ASCENDING',
      value: 'invoice_count,ASC'
    },
    {
      label: 'INVOICE COUNT - DESCENDING',
      value: 'invoice_count,DESC'
    }
  ],
  balance: [
    {
      label: 'BALANCE AMOUNT - ASCENDING',
      value: 'balance,ASC'
    },
    {
      label: 'BALANCE AMOUNT - DESCENDING',
      value: 'balance,DESC'
    }
  ],
  received_amount: [
    {
      label: 'RECEIVED AMOUNT - ASCENDING',
      value: 'received_amount,ASC'
    },
    {
      label: 'RECEIVED AMOUNT - DESCENDING',
      value: 'received_amount,DESC'
    }
  ],
  invoice_amount: [
    {
      label: 'INVOICE AMOUNT - ASCENDING',
      value: 'invoice_amount,ASC'
    },
    {
      label: 'INVOICE AMOUNT - DESCENDING',
      value: 'invoice_amount,DESC'
    }
  ],
  credit_amount: [
    {
      label: 'CREDIT AMOUNT - ASCENDING',
      value: 'credit_amount,ASC'
    },
    {
      label: 'CREDIT AMOUNT - DESCENDING',
      value: 'credit_amount,DESC'
    }
  ],
  debit_amount: [
    {
      label: 'DEBIT AMOUNT - ASCENDING',
      value: 'debit_amount,ASC'
    },
    {
      label: 'DEBIT AMOUNT - DESCENDING',
      value: 'debit_amount,DESC'
    }
  ],
  one_to_thirty_amount: [
    {
      label: '1 TO 30 AMOUNT - ASCENDING',
      value: 'one_to_thirty_amount,ASC'
    },
    {
      label: '1 TO 30 AMOUNT - DESCENDING',
      value: 'one_to_thirty_amount,DESC'
    }
  ],
  thirtyone_to_sixty: [
    {
      label: '31 TO 60 AMOUNT - ASCENDING',
      value: 'thirtyone_to_sixty,ASC'
    },
    {
      label: '31 TO 60 AMOUNT - DESCENDING',
      value: 'thirtyone_to_sixty,DESC'
    }
  ],
  before_sixty: [
    {
      label: 'BEFORE 60 AMOUNT - ASCENDING',
      value: 'before_sixty,ASC'
    },
    {
      label: 'BEFORE 60 AMOUNT - DESCENDING',
      value: 'before_sixty,DESC'
    }
  ],
  total_balance: [
    {
      label: 'TOTAL BALANCE - ASCENDING',
      value: 'total_balance,ASC'
    },
    {
      label: 'TOTAL BALANCE - DESCENDING',
      value: 'total_balance,DESC'
    }
  ],
  alloc: [
    {
      label: 'PAID AMOUNT - ASCENDING',
      value: 'alloc,ASC'
    },
    {
      label: 'PAID AMOUNT - DESCENDING',
      value: 'alloc,DESC'
    }
  ],
  unit_price: [
    {
      label: 'UNIT PRICE - ASCENDING',
      value: 'unit_price,ASC'
    },
    {
      label: 'UNIT PRICE - DESCENDING',
      value: 'unit_price,DESC'
    }
  ],
  quantity: [
    {
      label: 'QUANTITY - ASCENDING',
      value: 'quantity,ASC'
    },
    {
      label: 'QUANTITY - DESCENDING',
      value: 'quantity,DESC'
    }
  ],
  unit_discount: [
    {
      label: 'UNIT DISCOUNT - ASCENDING',
      value: 'unit_discount,ASC'
    },
    {
      label: 'UNIT DISCOUNT - DESCENDING',
      value: 'unit_discount,DESC'
    }
  ],
  unit_tax: [
    {
      label: 'UNIT TAX - ASCENDING',
      value: 'unit_tax,ASC'
    },
    {
      label: 'UNIT TAX - DESCENDING',
      value: 'unit_tax,DESC'
    }
  ],
  round_off_amount: [
    {
      label: 'ROUND OF AMOUNT - ASCENDING',
      value: 'round_off_amount,ASC'
    },
    {
      label: 'ROUND OF AMOUNT - DESCENDING',
      value: 'round_off_amount,DESC'
    }
  ],
  discount_amount: [
    {
      label: 'DISCOUNT - ASCENDING',
      value: 'discount_amount,ASC'
    },
    {
      label: 'DISCOUNT - DESCENDING',
      value: 'discount_amount,DESC'
    }
  ],
  contract_date: [
    {
      label: 'TRANSACTION DATE - ASCENDING',
      value: 'contract_date,ASC'
    },
    {
      label: 'TRANSACTION DATE - DESCENDING',
      value: 'contract_date,DESC'
    }
  ],
  contract_from_date: [
    {
      label: 'FROM DATE - ASCENDING',
      value: 'contract_from_date,ASC'
    },
    {
      label: 'FROM DATE - DESCENDING',
      value: 'contract_from_date,DESC'
    }
  ],
  contract_to_date: [
    {
      label: 'TO DATE - ASCENDING',
      value: 'contract_to_date,ASC'
    },
    {
      label: 'TO DATE - DESCENDING',
      value: 'contract_to_date,DESC'
    }
  ],
  receipt_amount: [
    {
      label: 'RECEIPT AMOUNT - ASCENDING',
      value: 'receipt_amount,ASC'
    },
    {
      label: 'RECEIPT AMOUNT - DESCENDING',
      value: 'receipt_amount,DESC'
    }
  ],
  balance_amount: [
    {
      label: 'BALANCE AMOUNT - ASCENDING',
      value: 'balance_amount,ASC'
    },
    {
      label: 'BALANCE AMOUNT - DESCENDING',
      value: 'balance_amount,DESC'
    }
  ],
  total_invoice_quantity: [
    {
      label: 'TOTAL INVOICE QUANTITY - ASCENDING',
      value: 'total_invoice_quantity,ASC'
    },
    {
      label: 'TOTAL INVOICE QUANTITY - DESCENDING',
      value: 'total_invoice_quantity,DESC'
    }
  ],
  sales_amount_invoice: [
    {
      label: 'INVOICE AMOUNT - ASCENDING',
      value: 'sales_amount,ASC'
    },
    {
      label: 'INVOICE AMOUNT - DESCENDING',
      value: 'sales_amount,DESC'
    }
  ],
  amount_contract: [
    {
      label: 'CONTRACT AMOUNT - ASCENDING',
      value: 'amount,ASC'
    },
    {
      label: 'CONTRACT AMOUNT - DESCENDING',
      value: 'amount,DESC'
    }
  ],
  discount_amount_unit: [
    {
      label: 'UNIT DISCOUNT - ASCENDING',
      value: 'discount_amount,ASC'
    },
    {
      label: 'UNIT DISCOUNT - DESCENDING',
      value: 'discount_amount,DESC'
    }
  ],
  trans_date_trans: [
    {
      label: 'TRANS DATE - ASCENDING',
      value: 'trans_date,ASC'
    },
    {
      label: 'TRANS DATE - DESCENDING',
      value: 'trans_date,DESC'
    }
  ],
  due_date: [
    {
      label: 'DUE DATE - ASCENDING',
      value: 'due_date,ASC'
    },
    {
      label: 'DUE DATE - DESCENDING',
      value: 'due_date,DESC'
    }
  ],
  invoiced_amount: [
    {
      label: 'INVOICED AMOUNT - ASCENDING',
      value: 'amount,ASC'
    },
    {
      label: 'INVOICED AMOUNT - DESCENDING',
      value: 'amount,DESC'
    }
  ],
  received_amount_paid: [
    {
      label: 'PAID AMOUNT - ASCENDING',
      value: 'received_amount,ASC'
    },
    {
      label: 'PAID AMOUNT - DESCENDING',
      value: 'received_amount,DESC'
    }
  ],
  purchase_amount_invoice: [
    {
      label: 'INVOICE AMOUNT - ASCENDING',
      value: 'purchase_amount,ASC'
    },
    {
      label: 'INVOICE AMOUNT - DESCENDING',
      value: 'purchase_amount,DESC'
    }
  ],
  quantity_send: [
    {
      label: 'QUANTITY SEND - ASCENDING',
      value: 'quantity_send,ASC'
    },
    {
      label: 'QUANTITY SEND - DESCENDING',
      value: 'quantity_send,DESC'
    }
  ],
  alloc_amount: [
    {
      label: 'ALLOC AMOUNT - ASCENDING',
      value: 'alloc,ASC'
    },
    {
      label: 'ALLOC AMOUNT - DESCENDING',
      value: 'alloc,DESC'
    }
  ],
  total_tax_total: [
    {
      label: 'TOTAL TAX - ASCENDING',
      value: 'total_tax,ASC'
    },
    {
      label: 'TOTAL TAX - DESCENDING',
      value: 'total_tax,DESC'
    }
  ],
  reference: [
    {
      label: 'REFERENCE - ASCENDING',
      value: 'reference,ASC'
    },
    {
      label: 'REFERENCE - DESCENDING',
      value: 'reference,DESC'
    }
  ]
};

const includeByValues = keys => {
  if (!Array.isArray(keys)) {
    throw new Error('keys must be an array');
  }
  return keys.reduce((result, key) => {
    if (commonSortData[key]) {
      result.push(...commonSortData[key]);
    }
    return result;
  }, []);
};

export const salesInvoiceReportSortData = [
  ...includeByValues([
    'trans_date',
    'amount',
    'total_tax',
    'round_off_amount',
    'alloc',
    'balance_amount'
  ])
];

export const salesOrderReportSortData = [
  ...includeByValues(['trans_date', 'amount', 'total_tax'])
];

export const salesDeliveryReportSortData = [...salesOrderReportSortData];

export const salesQuotationReportSortData = [...salesOrderReportSortData];

export const creditNoteReportSortData = [...salesOrderReportSortData];

export const salesReportByCustomersSortData = [
  ...includeByValues(['invoice_count', 'sales_amount', 'tax_amount'])
];

export const categoryWiseSalesReportSortData = [
  ...includeByValues([
    'total_invoice_amount',
    'total_tax',
    'total_invoice_quantity'
  ])
];

export const topCustomersSalesReportSortData = [
  ...includeByValues(['tax_amount', 'invoice_count', 'sales_amount_invoice'])
];

export const customerBalanceSummaryReportSortData = [
  ...includeByValues(['balance', 'invoice_amount', 'received_amount'])
];

export const customerBalanceStatementReportSortData = [
  ...includeByValues(['trans_date', 'credit_amount', 'debit_amount'])
];

export const agedCustomerReportSortData = [
  ...includeByValues([
    'one_to_thirty_amount',
    'thirtyone_to_sixty',
    'before_sixty',
    'total_balance'
  ])
];

export const unpaidSalesInvoiceReportSortData = [
  ...includeByValues([
    'trans_date',
    'amount',
    'total_tax',
    'alloc',
    'balance_amount'
  ])
];

export const salesInvoiceDetailsReportSortData = [
  ...includeByValues([
    'trans_date',
    'unit_price',
    'quantity',
    'discount_amount_unit',
    'unit_tax',
    'amount'
  ])
];

export const salesOrderDetailsReportSortData = [
  ...salesInvoiceDetailsReportSortData
];

export const salesQuotationDetailsReportSortData = [
  ...salesInvoiceDetailsReportSortData
];

export const salesDeliveryDetailsReportSortData = [
  ...salesInvoiceDetailsReportSortData
];

export const SalesmanBySalesInvoiceReportSortData = [
  ...includeByValues(['trans_date', 'amount'])
];

export const creditNoteDetailsReportSortData = [
  ...includeByValues([
    'trans_date_trans',
    'due_date',
    'unit_price',
    'quantity',
    'total_tax',
    'amount',
    'discount_amount'
  ])
];

export const contractReportSortData = [
  ...includeByValues([
    'contract_date',
    'contract_from_date',
    'contract_to_date',
    'invoice_amount',
    'receipt_amount',
    'amount_contract'
  ])
];

export const contractDetailsReportSortData = [
  ...includeByValues([
    'contract_date',
    'contract_from_date',
    'contract_to_date',
    'unit_price',
    'quantity',
    'discount_amount',
    'total_tax',
    'amount'
  ])
];

export const purchaseInvoiceReportSortData = [
  ...includeByValues([
    'trans_date',
    'invoiced_amount',
    'total_tax',
    'alloc',
    'reference'
  ])
];

export const purchaseOrderReportSortData = [...salesOrderReportSortData];

export const materialRequestReportSortData = [...salesOrderReportSortData];

export const purchaseRequestReportSortData = [...salesOrderReportSortData];

export const purchaseDeliveryReportSortData = [...salesOrderReportSortData];

export const supplierBalanceReportsSortData = [
  ...includeByValues(['invoice_amount', 'received_amount_paid', 'balance'])
];

export const supplierBalanceStatementReportSortData = [
  ...customerBalanceStatementReportSortData
];

export const agedSupplierReportSortData = [...agedCustomerReportSortData];

export const topSupplierPurchaseReportSortData = [
  ...includeByValues(['invoice_count', 'purchase_amount_invoice', 'total_tax'])
];

export const categoryWisePurchaseReportSortData = [
  ...categoryWiseSalesReportSortData
];

export const materialRequestDetailsReportSortData = [
  ...includeByValues([
    'trans_date',
    'quantity',
    'quantity_send',
    'discount_amount_unit',
    'unit_tax',
    'unit_price'
  ])
];

export const purchaseRequestDetailsReportSortData = [
  ...includeByValues([
    'trans_date',
    'unit_price',
    'quantity',
    'discount_amount_unit',
    'unit_tax',
    'amount',
    'reference'
  ])
];

export const purchaseOrderDetailsReportSortData = [
  ...purchaseRequestDetailsReportSortData
];

export const purchaseDeliveryDetailsReportSortData = [
  ...purchaseRequestDetailsReportSortData
];

export const purchaseInvoiceDetailsReportSortData = [
  ...purchaseRequestDetailsReportSortData
];

export const debitNoteDetailsReportSortData = [
  ...purchaseRequestDetailsReportSortData
];

export const unpaidPurchaseInvoiceReportSortData = [
  ...includeByValues([
    'trans_date',
    'total_tax_total',
    'amount',
    'alloc_amount'
  ])
];

export const debitNoteReportSortData = [...salesOrderReportSortData];
