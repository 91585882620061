import React from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import AppPagination from 'components/common/app-pagination/AppPagination';
import AddButton from 'components/add-button/AddButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useKeyDownNavigatorHook from 'hooks/useKeyDownNavigatorHook';
import DebitNoteEntryItem from '../Components/DebitNoteEntryItem';
import DebitNoteInfo from './DebitNoteInfo';
import ClassicThreeColumnLayoutFilter from 'module/Purchase/common/components/ClassicFilter/ClassicThreeColumnLayoutFilter';

const DebitNoteColumnView = ({ data, getData }) => {
  const Translate = useAxisproTranslate();
  const [permissions] = useOutletContext();
  const [queryParams] = useSearchParams();
  const { entry, ...allQueryParamsWithoutEntryItem } = Object.fromEntries([
    ...queryParams
  ]);

  const entryId = entry ?? data.data[0].id;
  const { containerRef } = useKeyDownNavigatorHook({
    focusElementClassName: 'active-three-column-tab-item',
    activeKeyName: 'id',
    activeItemRef: entryId,
    dataResult: data?.data,
    lastPageNumber: data?.meta?.last_page,
    to: '/purchase/debit-note',
    params: [
      {
        paramKey: 'entry',
        dataKey: 'id'
      }
    ]
  });

  return (
    <Row className="flex-fill mb-3">
      <Col sm={12} md={3} className="d-flex pe-md-2 ps-3" ref={containerRef}>
        <Card className="p-0 flex-fill mb-0">
          <Card.Header
            className="p-3 d-flex align-items-center justify-content-between"
            style={{ backgroundColor: 'whitesmoke' }}
          >
            <span className="fs--1 fw-bold text-dark text-uppercase">
              {Translate('Debit note')}
            </span>
            <div className="d-flex align-items-center">
              {permissions?.create_dn && (
                <AddButton to="/purchase/debit-note/add" />
              )}
              <div className="ms-2">
                <ClassicThreeColumnLayoutFilter moduleName="debit_note_list" />
              </div>
            </div>
          </Card.Header>

          <>
            <SimpleBar
              className={
                data &&
                data.meta &&
                data.meta.last_page &&
                data.meta.last_page === 1
                  ? 'simplebar-style-list-page-without-pagination'
                  : 'simplebar-style-list-page'
              }
            >
              {data.data.map((item, index) => (
                <DebitNoteEntryItem
                  key={index}
                  item={item}
                  active={item.id === entryId ? true : false}
                />
              ))}
            </SimpleBar>
            <Card.Footer className="border-top mt-3">
              {data.links && (
                <AppPagination
                  data={data}
                  url="/purchase/debit-note"
                  search={allQueryParamsWithoutEntryItem}
                />
              )}
            </Card.Footer>
          </>
        </Card>
      </Col>
      <Col sm={12} md={9} className="d-md-flex flex-column ps-sm-3 ps-md-0">
        <DebitNoteInfo debitNoteId={entryId} getData={getData} />
      </Col>
    </Row>
  );
};

DebitNoteColumnView.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  getData: PropTypes.func
};

export default DebitNoteColumnView;
